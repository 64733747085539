import React, { useState, useEffect } from "react";
import "./NewLandingPage.scss";
import { FadeLoader } from "react-spinners";
import Video from "../../../components/library/Video";
import FAQ from "../../../components/library/fAQ/FAQ-new";
import BannerCalendarWrapper from "../../../components/library/calendarWrapper/BannerCalendarWrapper";
import Calendar from "../../../components/library/calendar";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { Mixpanel } from "../../../mixpanel";
import UseTrackScrollHeight from "../../../hooks/UseTrackScrollHeight";
import { VscMenu, VscClose } from "react-icons/vsc";
import ReactModal from "react-modal";
import LogoCarousel from "../../../components/library/logoCarousel/LogoCarousel";
// import { isSafari } from "react-device-detect";
import { getFeatureVersion } from "../../../abTesting";
import LogoGrid from "../../../components/library/LogoGrid";
import WhatsAppIcon from "../../../components/library/WhatsAppIcon";

const NewLandingPage = () => {
  const [totalSavings, setTotalSavings] = useState("");
  const [loading, setLoading] = useState(true);

  const { source, affiliateCampaign, webCampaign } = useParams();

  const [modalSrc, setModalSrc] = useState();
  const [iframeSrc, setIframeSrc] = useState();
  const [iframeHeight, setIframeHeight] = useState();

  const [showMenu, setShowMenu] = useState(false);

  const closeMobileMenu = () => setShowMenu(false);

  const scheduleZoomClicked = () => {
    Mixpanel.track("schedule Zoom clicked", {
      page: source,
      affiliateCampaign,
      webCampaign,
      button: "header",
      landingPageVersion: getFeatureVersion("landingPageVersion"),
    });
    // window.ttq.track('InitiateCheckout', {});
  };

  if (source?.toLowerCase().includes("taboola")) {
    UseTrackScrollHeight(70, () =>
      window._tfa.push({ notify: "event", name: "view_content", id: 1490707 })
    );
  }

  Mixpanel.track("social media landing page view", {
    source,
    landingPageVersion: getFeatureVersion("landingPageVersion"),
  });

  useEffect(() => {
    const getTotalSavings = async () => {
      try {
        const url = `${
          window.location.href.includes("localhost")
            ? "http://localhost:4000"
            : "https://p.allbetter.co.il"
        }/api/public/total-savings`;
        const result = await fetch(url);
        const json = await result.json();

        setTotalSavings(() => {
          setLoading(false);
          return Math.round(json.totalSavings)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        });
      } catch (err) {
        console.log("Can't get total savings");
      }
    };
    getTotalSavings();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (showMenu) {
        setShowMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("touchmove", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, [showMenu]);

  const generateDigitBoxes = () => {
    const characters = totalSavings.split("").reverse();

    const digitBoxes = characters.map((char, i) => {
      if (char === ",") {
        return (
          <span key={i} className="comma">
            {char}
          </span>
        );
      } else {
        return (
          <div key={i} className="white-box">
            {char}
          </div>
        );
      }
    });
    digitBoxes.push(
      <span key="sheqel-sign" className="sheqel-sign">
        ₪
      </span>
    );
    return digitBoxes;
  };

  const generateNavLinks = (isMobile) => {
    const ulClassName = isMobile
      ? showMenu
        ? "nav-menu-active"
        : "nav-menu-non-active"
      : "";
    const liClassName = isMobile ? "nav-links" : "";
    const navLinks = [
      { text: "איך זה עובד", href: "#section5", onClick: closeMobileMenu },
      { text: "שאלות נפוצות", href: "#section8", onClick: closeMobileMenu },
      { text: "אבטחה ופרטיות", href: "#section7", onClick: closeMobileMenu },
      {
        text: "צרו קשר",
        href: "#zoom-schedule",
        onClick: () => {
          scheduleZoomClicked();
          closeMobileMenu();
        },
      },
    ];
    return (
      <ul className={ulClassName}>
        {navLinks.map((link, i) => (
          <li key={i} className="nav-item">
            <AnchorLink
              href={link.href}
              onClick={link.onClick}
              className={liClassName}
            >
              {link.text}
            </AnchorLink>
          </li>
        ))}
      </ul>
    );
  };

  const generateHeader = () => {
    if (affiliateCampaign?.toLowerCase() === "bf") {
      return (
        <>
          <div>סוכן AI שעושה סדר</div>
          <div>בכסף שלך</div>
          <p>
            מטפל עבורך בכל הנושאים הפיננסים {<br />} ומשיג את התנאים הטובים
            ביותר בשוק
          </p>
        </>
      );
    }
    if (webCampaign) {
      return (
        <>
          <div>תנו לטכנולוגיה לחסוך</div>
          <div>לכם מאות אלפי שקלים</div>
          <p>
            מטפלים בשבילכם בכל הנושאים הפיננסים{<br />} ומשיגים לכם את התנאים
            הטובים ביותר בשוק.
          </p>
        </>
      );
    }
    return (
      <>
        <div>תנו לטכנולוגיה לחסוך</div>
        <div>לכם מאות אלפי שקלים</div>
        <p>
          מטפלים בשבילכם בכל הנושאים הפיננסים{<br />} ומשיגים לכם את התנאים
          הטובים ביותר בשוק.
        </p>
      </>
    );
  };

  const showModalImage = (src) => {
    setModalSrc(src);
  };

  const showModalIframe = (src, iframeHeight) => {
    setIframeSrc(src);
    setIframeHeight(iframeHeight);
  };
  const isSafari = /^((?!Chrome|Android).)*Safari/.test(navigator.userAgent);
  // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  console.log(isSafari);

  return (
    <div
      className="landing-page"
      style={{ overflow: showMenu ? "hidden" : "auto" }}
    >
      <div className="header">
        <div className="header-body lp-container">
          <img src="/images/all/Betterlogo.svg" alt="Better" />
          <div className="header-nav">{generateNavLinks(false)}</div>
          <div className={isMobile ? "mobile-buttons-left" : ""}>
            <div className="header-zoom">
              <AnchorLink
                href="#zoom-schedule"
                onClick={scheduleZoomClicked}
                className="desktop-button"
              >
                <button className="blue-button">לתיאום פגישת היכרות</button>
              </AnchorLink>
              <AnchorLink
                href="#zoom-schedule"
                onClick={scheduleZoomClicked}
                className="mobile-button"
              >
                <img src="/images/all/mobile-Video-icone.svg" />
              </AnchorLink>
            </div>
            <div className="header-menu">
              <div className="menu-icon" onClick={() => setShowMenu(!showMenu)}>
                {showMenu ? <VscClose /> : <VscMenu />}
              </div>
              {generateNavLinks(true)}
            </div>
          </div>
        </div>
      </div>

      <div className="section1 lp-container">
        <div className="section1-text">
          {generateHeader()}
          <p>
            <b>חיסכון ממוצע ללקוח:</b>&nbsp;&nbsp;
            {/* {isMobile && <br />} */}
            <span className="section1-text-amount">261,000 ש״ח</span>
          </p>
          {!isMobile ? (
            <div className="section1-button">
              <AnchorLink href="#zoom-schedule">
                <button className="blue-button">לתיאום היכרות בזום</button>
              </AnchorLink>
            </div>
          ) : null}
        </div>
        {isMobile ? (
          <div className="section1-button">
            <AnchorLink href="#zoom-schedule">
              <button className="blue-button">לתיאום היכרות בזום</button>
            </AnchorLink>
          </div>
        ) : null}
        <div className="section1-gif">
          <video autoPlay loop muted controls={false} playsInline>
            <source
              src="https://better-website-static.s3.eu-central-1.amazonaws.com/talking-heads-high-res.mp4"
              type="video/mp4"
              className="gif-video"
            />
          </video>
        </div>
      </div>

      {isMobile ? (
        <LogoCarousel mobile={true} />
      ) : (
        <div className="section2">
          <LogoGrid
            numRows={2}
            numImagesPerRow={6}
            imageDirectory="/images/all/companies-logos/landing-page-logos"
          />
        </div>
      )}

      <div className="section5" id="section5">
        <div className="section5-text">
          <div className="section5-text-title">איך זה עובד?</div>
          <p>
            הלקוחות שלנו הם אנשים כמוך שהכסף שלהם חשוב להם ורוצים לשפר את הסטטוס
            שלהם בלי מאמץ, האלגוריתם שלנו עושה את הכל בשבילכם ומטפל בנושאים
            הפיננסיים שאתם לא רוצים לגעת בהם מסביב לשעון כדי שתוכלו להתעסק במה
            שאתם טובים בו{" "}
            <span className="marked-text">וחוסך לכם בממוצע 261,000 ש”ח.</span>
          </p>
        </div>
        <div className="section5-body lp-container">
          <div className="section5-body-right">
            <div
              className="section5-body-right-number"
              style={{
                background:
                  "linear-gradient(0.4deg, #1A39DA -709.69%, #5AE3CF 102.53%)",
              }}
            >
              1.
            </div>
            <div className="section5-body-right-text">
              <div className="section5-body-right-text-right">
                נפגשים
                <br /> ב-ZOOM <br />
                להיכרות
                <br />
                <br />
              </div>
              <div className="section5-body-right-text-left">
                שיחת זום עם כלכלן שלנו, מקבלים את אישורכם לאסוף את המסמכים
                המעצבנים ומשם עוברים לווטסאפ.
              </div>
            </div>
            <div
              className="section5-body-right-number"
              style={{
                background:
                  "linear-gradient(0deg, #1A39DA -385.71%, #5AE3CF 426.43%)",
              }}
            >
              2.
            </div>
            <div className="section5-body-right-text">
              <div className="section5-body-right-text-right">
                עובדים
                <br /> בשבילכם
                <br /> 24/7
              </div>
              <div className="section5-body-right-text-left">
                הטכנולוגיה שלנו סורקת באופן מתמשך את כל התנאים הטובים בשוק ומזהה
                הזדמנויות לחסכון ואתם יכולים להיות רגועים שאתם תמיד מקבלים את
                התנאים הכי טובים.
              </div>
            </div>
            <div
              className="section5-body-right-number"
              style={{
                background:
                  "linear-gradient(360deg, #1A39DA 0%, #5AE3CF 817.14%)",
              }}
            >
              3.
            </div>
            <div className="section5-body-right-text">
              <div className="section5-body-right-text-right">
                חוסכים <br />
                עבורכם
              </div>
              <div className="section5-body-right-text-left">
                כל פעם שנזהה חסכון נשלח לכם הודעת ווטסאפ עם כל הפרטים ורק אחרי
                אישור מפורש שלכם נבצע את החסכון עבורכם.
              </div>
            </div>
          </div>
          <div className="section5-body-left">
            <div className="section5-body-left-zoom">
              <img src="/images/all/landing-page-zoom-mayme.svg" alt="zoom" />
            </div>
            <div className="section5-body-left-phone">
              <img src="/images/all/Whatsapp-conversation.svg" alt="phone" />
            </div>
          </div>
        </div>
        <div
          className={`section5-lower-text ${
            isSafari ? "section5-lower-text-safari" : ""
          }`}
        >
          תנו לטכנולוגיה {isMobile && <br />}
          <span>לחסוך בשבילכם</span>
        </div>
        <div className="section5-circle"></div>
        {!isMobile ? (
          <div className="section5-wave">
            <img src="/images/all/wave.svg" alt="wave" />
          </div>
        ) : (
          <div className="section5-wave-mobile"></div>
        )}
      </div>

      <div className={`section6 ${isSafari ? "section6-safari" : ""}`}>
        {!isMobile ? (
          <>
            <div className="section6-text" style={{ paddingTop: "20px" }}>
              רוצה להיות בשליטה בלי מאמץ ולחסוך מאות אלפי
            </div>
            <div className="section6-text">
              שקלים בעמלות מיותרות ודמי ניהול מוגזמים?{" "}
            </div>
          </>
        ) : (
          <div className="section6-text" style={{ paddingTop: "20px" }}>
            רוצה להיות בשליטה בלי מאמץ ולחסוך מאות אלפי שקלים בעמלות מיותרות
            ודמי ניהול מוגזמים?
          </div>
        )}
        <div className="section6-text-special">
          תאמ/י היום שיחה עם מומחה פיננסי של
        </div>
        <div className="section6-logo">
          <img src="/images/all/better-logo-white.svg" alt="Better" />
        </div>
        <div className="section6-bottom">
          <AnchorLink href="#zoom-schedule" style={{ textDecoration: "none" }}>
            <button className="section6-bottom-button">
              תאמו היכרות בזום
              <ArrowBackIosIcon fontSize="small" />
            </button>
          </AnchorLink>
        </div>
      </div>

      <div className={`section4 ${isSafari ? "section4-safari" : ""}`}>
        <div className="section4-text">
          <div>
            צפו בלקוחות מרוצים שחסכנו להם {<br />}
            <span className="gradient-text-section4">מאות אלפי שקלים</span>
          </div>
        </div>
        <div className="section4-video">
          <Video
            configs={{ controls: true, autoPlay: false }}
            poster={"/images/all/video-thumbnail.png"}
            sourceList={[
              {
                src: "https://better-website-static.s3.eu-central-1.amazonaws.com/Better_Testot_July_wide.mp4",
                type: "video/mp4",
              },
            ]}
          />
        </div>
      </div>

      <div className="section3">
        <div className="section3-body">
          <div className="section3-text">עד כה חסכנו ללקוחותינו</div>
          <div className="section3-amount">
            {loading ? (
              <FadeLoader size={30} color={"#5AE3CF"} />
            ) : (
              generateDigitBoxes()
            )}
          </div>
        </div>
      </div>

      <div className="section7" id="section7">
        <div className="lp-container">
          <div className="section7-title">
            אבטחה מקסימלית בהתאם לרגולצייה הפיננסית
          </div>
          <div className="section7-body">
            <div className="section7-body-grid">
              <div className="section7-body-grid-row">
                <div className="section7-body-grid-right">
                  <img src="/images/all/meyupeKoah.svg" alt="meyupeKoah" />
                </div>
                <div className="section7-body-grid-left">
                  <div className="section7-body-grid-right-text">
                    מרשם מיופי כוח בתמורה של בנק ישראל - מיופה כוח בתמורה
                  </div>
                </div>
              </div>
              <div className="section7-body-grid-row">
                <div className="section7-body-grid-right">
                  <img
                    src="/images/all/misradHamishpatim.svg"
                    alt="misradHamishpatim"
                  />
                </div>
                <div className="section7-body-grid-left">
                  <div className="section7-body-grid-right-text">
                    משרד המשפטים - מאגר מידע רשום
                  </div>
                </div>
              </div>
              <div className="section7-body-grid-row">
                <div className="section7-body-grid-right">
                  <img src="/images/all/yoazeyMas.png" alt="yoazeyMas" />
                </div>
                <div className="section7-body-grid-left">
                  <div className="section7-body-grid-right-text">
                    מועצת יועצי המס - רישיון יועץ מס
                  </div>
                </div>
              </div>
              <div className="section7-body-grid-row">
                <div className="section7-body-grid-right">
                  <img src="/images/all/shukHaHon.svg" alt="shukHaHon" />
                </div>
                <div className="section7-body-grid-left">
                  <div className="section7-body-grid-right-text">
                    רישיון שוק ההון, ביטוח וחיסכון - בעל רישיון פנסיוני
                  </div>
                </div>
              </div>
            </div>
            <div className="section7-body-lock">
              <img src="/images/all/lock-image-black.svg" alt="lock" />
            </div>
          </div>
          <div className="section7-button">
            <AnchorLink
              href="#zoom-schedule"
              style={{ textDecoration: "none" }}
            >
              <button className="section6-bottom-button">
                תאמו היכרות בזום
                <ArrowBackIosIcon fontSize="small" />
              </button>
            </AnchorLink>
          </div>
        </div>
      </div>

      <div className="section8" id="section8">
        <div className="faq-container">
          <div className="section8-title">נשמח לענות{<br />} על כל שאלה</div>
          <div className="section8-faq">
            <FAQ />
          </div>
        </div>
        <div className="section8-button-container">
          <AnchorLink href="#zoom-schedule" style={{ textDecoration: "none" }}>
            <button
              className="section8-button-container-button"
              style={{ fontWeight: "600" }}
            >
              לתיאום היכרות בזום
            </button>
          </AnchorLink>
        </div>
      </div>

      <div className="section9">
        <div className="section9-title">
          תאמו פגישה {isMobile && <br />}
          <span>והחלו לחסוך</span>
        </div>
        <div className="section9-body">
          <div className="section9-body-right">
            <BannerCalendarWrapper
              Calendar={Calendar}
              calendarOnly={true}
              affiliateCampaign={affiliateCampaign}
              webCampaign={webCampaign}
              styles={{
                boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.25)",
                height: isMobile ? "650px" : "600px",
                width: isMobile ? "100%" : "500px",
              }}
            />
          </div>
          <div className="section9-body-left">
            <p>
              תאמו היום שיחת הכרות עם כלכלן של Better והצטרפו לאלפי הייטקיסטים
              שחוסכים מאות אלפי שקלים בעמלות ודמי ניהול מוגזמים.
            </p>
            <p>
              <span className="marked-text">
                בחרו בלוח את המועד הנוח ביותר לשיחה
              </span>{" "}
              בת 30 דקות עם מומחי הפיננסים שלנו, נשלח לכם זימון למייל ונתקשר
              מולכם בוואטסאפ.
            </p>
            {/* <p><span className="marked-text">תאמו היום שיחת הכרות</span> עם כלכלן של Better והצטרפו לאלפי הייטקיסטים שחוסכים מאות אלפי שקלים בעמלות ודמי ניהול מוגזמים.</p> */}
          </div>
        </div>
        <div className="section9-circle"></div>
      </div>

      <div className="footer">
        <div className="footer-body lp-container">
          <div className="footer-body-license">
            <span>רשיונות ומפקחים פיננסיים</span>
            <ul>
              <li
                onClick={() =>
                  showModalImage("/images/all/docs/power-of-attorney.png")
                }
              >
                רישיון בנק ישראל - מיופה כוח בתמורה
              </li>
              <li
                onClick={() =>
                  showModalImage("/images/all/docs/better-pension-license.jpg")
                }
              >
                רישיון שוק ההון, ביטחון וחיסכון - בעל רישיון פנסיוני
              </li>
              {/* <li>רישיון הרשות לניירות ערך - נותן שירותי מידע פיננסי</li> */}
              <li
                onClick={() =>
                  showModalImage("/images/all/docs/tax-license-new.jpg")
                }
              >
                רישיון מס הכנסה - חברת ייעוץ מס
              </li>
              <li
                onClick={() =>
                  showModalImage("/images/all/docs/registered-database.png")
                }
              >
                משרד המשפטים - מאגר מידע רשום
              </li>
            </ul>
          </div>
          <div
            className={isMobile ? "horizontal-line" : "vertical-line"}
            style={isMobile ? { order: 2 } : {}}
          ></div>
          <div className="footer-body-better">
            <img
              src="/images/all/new-landing-page-better-logo-white.svg"
              alt="Better"
            />
            <p>תנו לטכנולוגיה לחתוך לכם את העמלות</p>
          </div>
          <div
            className={isMobile ? "horizontal-line" : "vertical-line"}
            style={isMobile ? { order: 4 } : {}}
          ></div>
          <div className="footer-body-sm">
            <div className="footer-body-sm-icons">
              <a
                href="https://www.instagram.com/allbetter_finance"
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
              </a>
              <a
                href="https://www.linkedin.com/company/betterpfm"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
              </a>
              <a
                href="https://twitter.com/BetterPFM"
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
              </a>
              <a
                href="https://www.facebook.com/people/Better/100086255880240/"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon sx={{ color: "rgba(90, 227, 207, 1)" }} />
              </a>
            </div>
            <div className="footer-body-sm-text">
              <p>
                דרך בגין 114, תל אביב יפו &nbsp;&#8226;&nbsp;{" "}
                <a href="mailto:support@allbetter.co.il">
                  support@allbetter.co.il
                </a>{" "}
              </p>
              <p>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    showModalIframe(
                      "https://better-website-static.s3.eu-central-1.amazonaws.com/better-terms-of-use.html",
                      7250
                    )
                  }
                >
                  תנאי שימוש
                </span>
                &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    showModalIframe(
                      "https://better-website-static.s3.eu-central-1.amazonaws.com/better-privacy.html",
                      2200
                    )
                  }
                >
                  מדיניות פרטיות
                </span>
              </p>
              <ReactModal
                className="modal"
                isOpen={!!modalSrc || !!iframeSrc}
                style={{
                  overlay: {
                    background: "rgba(0,0,0,0.4)",
                    "overflow-y": "auto",
                  },
                }}
                overlayElement={(props, contentElement) => (
                  <div
                    {...props}
                    onClick={() => {
                      setModalSrc(null);
                      setIframeSrc(null);
                    }}
                  >
                    {contentElement}
                  </div>
                )}
              >
                <div
                  className="close-modal"
                  onClick={() => {
                    setModalSrc(null);
                    setIframeSrc(null);
                  }}
                >
                  X
                </div>
                <div className="content">
                  {iframeSrc ? (
                    <iframe
                      frameBorder="0"
                      onmousewheel=""
                      width="100%"
                      height={iframeHeight}
                      scrolling="no"
                      src={iframeSrc}
                    />
                  ) : null}
                  {modalSrc ? <img src={modalSrc} alt="" /> : null}
                </div>
              </ReactModal>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppIcon />
    </div>
  );
};

export default NewLandingPage;
